export default {
  useColorSchemeMediaQuery: true,

  colors: {
    text: `#1d2021`,
    background: `#f8f8f8`,
    sectionBackground: `#fafafa`,
    footerBackground: `#f2f2f2`,
    action: `royalblue`,
    tag: `rgb(0 0 0 / 3.75%)`,
    divider: `rgb(0 0 0 / 12.5%)`,
    selection: `#c1e0ff`,

    characterPrimaryBackground: `gold`,
    characterPrimaryText: `black`,

    modes: {
      dark: {
        text: `#f8f8f8`,
        background: `#0e1010`,
        sectionBackground: `#111`,
        footerBackground: `#000`,
        action: `gold`,
        tag: `rgb(255 255 255 / 3.75%)`,
        divider: `rgb(255 255 255 / 12.5%)`,
        selection: `#7d8585`,
      },
    },
  },

  fonts: {
    body: `system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", sans-serif`,
    heading: `inherit`,
    // heading: `IBM Plex Serif, serif`,
    monospace: `IBM Plex Mono, Menlo, monospace`,
  },

  fontWeights: {
    body: 400,
    heading: 500,
    bold: 600,
  },

  lineHeights: {
    body: 1.7,
    heading: 1.1275,
  },
}
