// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-components-character-tsx": () => import("./../../../src/components/character.tsx" /* webpackChunkName: "component---src-components-character-tsx" */),
  "component---src-components-collection-tsx": () => import("./../../../src/components/collection.tsx" /* webpackChunkName: "component---src-components-collection-tsx" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-characters-tsx": () => import("./../../../src/pages/characters.tsx" /* webpackChunkName: "component---src-pages-characters-tsx" */),
  "component---src-pages-collections-tsx": () => import("./../../../src/pages/collections.tsx" /* webpackChunkName: "component---src-pages-collections-tsx" */),
  "component---src-pages-cycle-tsx": () => import("./../../../src/pages/cycle.tsx" /* webpackChunkName: "component---src-pages-cycle-tsx" */),
  "component---src-pages-grid-tsx": () => import("./../../../src/pages/grid.tsx" /* webpackChunkName: "component---src-pages-grid-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */)
}

